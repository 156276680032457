import React from 'react'
import {motion} from "framer-motion";
import UnrealLogo from "../icons/unreal_logo.svg";
import Csilla from "../images/csilla_profile.webp"
import Robi from "../images/robi_profile.webp"

export default function About() {
  return (
    <div className='about-container'>
        <div className="bg-logo-unreal"><img src={UnrealLogo} alt=""/></div>

        <motion.div className='about-box' initial={{opacity: 0, x: -100}} animate={{opacity: 1, x: 0}}>
            <div className='image-container'>
                <div className='img-box' style={{backgroundImage: `url(${Robi})`}}/>
                <p>Vári Róbert</p>
                <small>alapító, tanfolyamvezető</small>
            </div>
            
            <h3>Az oktatások fejlesztésével és a Game Craft tanfolyamok vezetésével foglalkozom. <br /><br />
            Sok év tapasztalattal rendelkezem a szakmában és ezt a tapasztalatot felhasználva szeretnék egy olyan iskolát a hazai piacon, ami nyugati színvonalat képvisel és értékes tudást ad a játékfejlesztő szakma iránt érdeklődőknek. 
            </h3>
        </motion.div>

        <motion.div className='about-box' initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}}>
            <div className='image-container'>
                <div className='img-box' style={{backgroundImage: `url(${Csilla})`}}/>
                <p>Rödönyi Csilla</p>
                <small>grafikus, animátor</small>
            </div>
            
            <h3>A Game Craft tanfolyamok játékainak képi világával, illusztrációival, animációval és karakter tervezéssel foglalkozom. 
                <br /><br /> 
                Többnyire stilizált műfajban dolgozom és pixelartokat is készítek. Animátorként első sorban a 2D animáció a fő profilom, de a 3D sem ismeretlen a számomra.
                <br />
                <br />
                Weboldal: <a href="https://cheellart.hu/" rel="noopener noreferrer" target="_blank">cheellart.hu</a>
            </h3>
        </motion.div>
    </div>
  )
}
