import React from 'react';
import BGVideo from "../../videos/DroneFighter_web.mp4"
import Poster from "../../images/drone_fighter_poster.webp"
import CourseDetailsComponent from "./CourseDetailsComponent"
import YouTube from 'react-youtube';

function GamedevKezdo() {
    const opts = {
        height: window.innerWidth < 500? "250":"460",
        width: '100%',
      };

      const player_style = {
        margin: window.innerWidth < 500? "10px 0":"20px",
      }

    return (
        <CourseDetailsComponent
            poster={Poster}
            title={<h1 className="bold-title"><span className="blue-text">Game Craft:</span> Kezdő</h1>}
            subtitle = {<h1>Drone Fighter <small>(twin stick shooter)</small></h1>}
            description = {
                <div>
                    <h3>
                        Az első workshop projektünkkel visszamegyünk a játékok és a játékgépek hőskorába. Ezeket a játékokat olyan gépeken játszották ahol két joystick-et használtál. Az egyikkel irányítottad az űrhajódat, a másikkal a célkeresztet mozgattad és azon volt a tűz gomb is. Innen származik a zsáner neve: Twin Stick Shooter.
                        <br/><br/>Azért választottam ezt a projektet a kezdő oktatásra, mert egy ilyen játék fejlesztése viszonylag egyszerű. Mindössze ügyességre és kitartásra van szükség ahhoz, hogy legyőzd az ellenséges űrhajókat, amik körökben érkeznek egyre komolyabb fegyverzettel. Nincs tehát komplex történet és skill rendszer. Tökéletes lesz az alapok megtanulásához.
                    </h3>

                    <h3>
                        <br />
                        <div>Gameplay:</div>
                        <YouTube videoId="JkQD1qOdHBU" opts={opts} style={player_style}/>
                        <br/>Töltsd le és próbáld ki: <a href="https://gamecraftschool.itch.io/drone-fighter" rel="noopener noreferrer" target="_blank">itch.io</a>
                    </h3>
                </div>
            }
            
            video_bg={BGVideo}

            tematics={
                [
                    "Unreal Engine alapok", 
                    "Viewport navigáció és munka az Editorban",
                    "Blueprint alapok",
                    "Input események kezelése",
                    "Hibakeresés: Blueprint Debugger",
                    "Unreal Material Editor",
                    "Particle FX: Niagara",
                    "User Interface",
                    "Hangok effektek és zenék",
                ]
            }
        />
    );
}

export default GamedevKezdo;