import React, {useContext, useEffect, useState, Fragment} from 'react';
import UnrealBG from "../../images/UnrealEngine_BG.webp";
import {useNavigate} from "react-router-dom";
import OfflineCourseCard from './OfflineCourseCard';

import BlackTextBox from "../widgets/BlackTextBox";
import {CourseContext} from "../Contexts/CourseContext";
import {numberWithCommas} from "../../utilities";
import { motion } from "framer-motion"
import {validateEmail} from "../../utilities";
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";


function GroupItem({data}){
  const {set_dates_visible} = useContext(CourseContext)
  const navigate = useNavigate()

  const button_clicked = (e) => {
      e.preventDefault()
      set_dates_visible(false)
      if(window.innerWidth < 500){
        window.location.href = `/registration/${data.name}?offline=${data.offline_course}`;
      }else{
        navigate(`/registration/${data.name}?offline=${data.offline_course}`)
      }
  }

  return(
      <div className="item">
          <div className="group-container">
              <h3 className="group-name"><div>Csoport: <span className="blue-text">{data.name}</span></div>
                  {
                      data.can_be_subscribed?
                          <button onClick={button_clicked} className="button"><i className="fas fa-graduation-cap"/> Beiratkozom</button>
                          :
                          <small>Ez a csoportunk már elindult :(</small>
                  }

              </h3>

              {
                  Object.keys(data.sessions).map((month, i) =>
                      <div key={i} className="month-container">
                          <div className="month">{month}</div>
                          <div>
                              {data.sessions[month].map((day, index) =>
                                  <div key={index} className={`day-item ${day.finished&& "finished"}`}>
                                      <div>{day.finished&& <i className="far fa-check-circle"/>} {day.day} {day.day_name}</div> <div>{day.start}-{day.end}</div>
                                  </div>
                              )}
                          </div>
                      </div>
                  )
              }

          </div>
      </div>
  )
}

function GroupList (){
  const {course_data} = useContext(CourseContext)
  const [groups, set_groups] = useState([])

  useEffect(() => {
      set_groups(course_data.groups.filter(group => group.can_be_subscribed))
  }, [course_data])

  if(!course_data.groups) return null

  return (
      <div className={`${groups.length > 0? "calendar-container" : ""}`}>
          {
              groups.length > 0?
              // false?
                  <Fragment>
                      <br />
                      {
                          groups.length > 1?
                          // false?
                          <h2 className='center-text'>Ebből az oktatásból több csoport is indul. Válaszd ki a neked megfelelőt.</h2>
                          :
                          <h2 className='center-text'>Előadások időpontjai</h2>                            
                      }{
                        course_data.offline_course?
                        <Fragment>
                          <div className="info"><i className="fas fa-info-circle"/> Ez a tanfolyam letölthető videók formájában érhető el.<br /> <br /> Jelentkezéseddel a hallgatók számára létrehozott csoportba is bekerülsz ahol ha elakadtál segítünk az önálló tanulásban.</div>
                        </Fragment>
                        :
                        <div className="info"><i className="fas fa-info-circle"/> Ha valamelyik időpont neked nem jó, akkor sincs probléma. Az előadásokat felvételről is megnézheted.</div>
                      }
                      
                      <br />
                      {
                          groups.map(group_data => <GroupItem data={group_data}/>)
                      }
                  </Fragment>
                  :
                  <OfflineCourseCard/>
          }
      </div>
  )
}

function MailingList(){
  const {course_data} = useContext(CourseContext)
  const [email, set_email] = useState("")
  const [error, set_error] = useState(null)
  const [sending, set_sending] = useState(false)
  const [sent, set_sent] = useState(false)

  const css_override = {
    alignSelf: "center",
    jusifySelf: "center",
    width: "100%"
  }

  const API_URL = process.env.REACT_APP_API_URL

  const check_form = () => {
    if(email.length === 0 || !validateEmail(email)) {
      set_error("Az email formátuma nem megfelelő!")
      return false
    }

    return true
  }

  const registration_clicked = async (e) => {
    e.preventDefault()

    set_error(null)
    let form_valid = check_form()
    if(!form_valid) return

    set_error(null)
    set_sending(true)

    try{
        await axios({
            method: "post",
            url: `${API_URL}/api/students/mailinglist/`,
            data:{
              course: course_data.slug,
              email: email
            }
        })

        set_sending(false)
        set_sent(true)
    }catch (err){
        set_error(err.response.data.message)
        set_sending(false)
        return
    }
  }


  return <Fragment>
    <hr />
    <div className='mailing-list-layout'>
      <h2 className='center-text'>Ez a tanfolyam fejlesztés alatt áll.</h2>
      <hr />

      {sending?
        <PacmanLoader color={"#00B6F0"} loading={true} size={16} css={css_override}/>
        :
        sent?
        <Fragment>
          <h3 className='center-text'>Örülünk, hogy érdekel a tanfolyam. Levélben értesítünk ha elindult a beíratkozás.</h3>
        </Fragment>
        :
        <Fragment>
          <h3 className='center-text'>Iratkozz fel hírlevelünkre, hogy ne maradj le róla!</h3>
          
          <small className="error">{error}</small>
          
          <form className='mailing-list-field'>
            <input type="email" placeholder='Email' value={email} onChange={e => set_email(e.target.value)}/>
            <button onClick={registration_clicked}>Feliratkozás</button>
          </form>
        </Fragment>
      }

      <hr />
    </div>
  </Fragment>
}

export default function CourseDetails({poster, title, subtitle, description, video_bg, tematics, tematicsBG}) {
  const {course_data} = useContext(CourseContext)

  if(!course_data.id){
    return null
  }

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}}>
    <Fragment>
      <div className="course-title-container">
        {/* poster */}
        <motion.img src={poster} alt="" initial={{x: -200}} animate={{x: 0}}/>

        {/* Course title and description */}
        <div className='course-title-content'>
          <motion.div initial={{x: 200}} animate={{x: 0}}>{title}</motion.div>
          
          <motion.div initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{ delay: 0.3 }} className="subtitle-text">{subtitle}</motion.div>
          
          <hr />
          {description}

          {
            course_data.in_development?
            <MailingList/>
            :
            course_data.price>0&&
            <Fragment>
              <hr/>
              <h1>Az oktatás díja: <b><span className="blue-text">{numberWithCommas(course_data.price)} Ft</span></b> <small><small>({numberWithCommas(course_data.netto)} + ÁFA)</small></small></h1>
              {
                course_data.months > 1 && <p className="info">Havi részletekben: {course_data.months} x {numberWithCommas(course_data.price / course_data.months)} Ft</p>
              }
              
              <br />
              {
                !course_data.offline_course?
                <Fragment>
                  <h3>Óraszám: <b><span className="blue-text">{course_data.hours} óra</span></b></h3>
                  <h3><b>Oktatás jellege:</b> Online, élő oktatás</h3>
                  <br />
                </Fragment>
                :
                <h3><b>Oktatás jellege:</b> Letölthető videók + hallgatói csoport <br /></h3>
              }
            </Fragment>
          }
        </div>
      </div>

      {video_bg&&
          <div className="video-text-container">
            <video className='video-player' autoPlay loop muted playsInline>
                <source src={video_bg} type='video/mp4' />
            </video>
          </div>}

      {
        !course_data.in_development&&
        <Fragment>
          {/* tematics and dates */}
          <div className='course-details-layout'>
            <div>
              <div className="image-text-container" style={{backgroundImage: `url(${tematicsBG? tematicsBG:UnrealBG})`}}>
                <div className="bg-box"/>
                <BlackTextBox
                    text={
                      <div>
                        <h1>Tematika</h1>
                        <br />
                        <h3>
                            <ul>
                              {
                                tematics.map(i => <li key={i}>{i}</li>)
                              }
                            </ul>
                        </h3>
                      </div>
                    }
                  />
              </div>
            </div>

            <div>
              <GroupList/>
            </div>
          </div>
        </Fragment>
      }
    </Fragment>
    </motion.div>

  )
}