import React from 'react';

function Impresszum(props) {
    return (
        <div className="center-text-box">
            <h2>Impresszum</h2>
            <div className="grid">
                <p><b className="blue-text">A vállalkozás neve:</b> InkSpiral Kft.</p>
                <p><b className="blue-text">Székhely: </b>2045 Törökbálint, Téglagyár utca 15.</p>
                <p><b className="blue-text">Adószám:</b> 32193213-2-13</p>
                <p><b className="blue-text">Telefonszám:</b> +36 20 488-3278</p>
                <p><b className="blue-text">E-mail:</b> mail.gamecraft@gmail.com</p>
                <p><b className="blue-text">Weboldal:</b> https://www.gamecraft.hu/</p>
                <p><b className="blue-text">Tárhelyszolgáltató:</b> Cégnév: Linode LLC. Székhely: 249 Arch St Philadelphia, PA, 19106-1915 United States Elérhetőség: (609) 297-5037</p>
                <p><b className="blue-text">Nyilvántartó hatóság:</b> Nemzeti Adó-és Vámhivatal</p>
            </div>
        </div>
    );
}

export default Impresszum;