import React from 'react'

export default function OfflineCourseCard() {
  return (
    <div style={{textAlign: "center"}}> 
        <br />
        <h1>Sajnos erre a tanfolyamra elfogytak az időpontok :(</h1>
        <hr />
        <h3>Ha nem szeretnél várni a következő csoportra akkor a tanfolyam teljes anyaga megvehető letölthető formában is. Az önálló tanuláshoz személyes segítséget kapsz.</h3>
        <br />
        <h2>Küldj egy levelet a <strong>mail.gamecraft@gmail.com</strong> címre a részletekért.</h2>
        <hr />
        <h3>Ha szeretnél értesülni a jövőben induló oktatásainkról akkor </h3>
        <a href="https://discord.gg/nRTjD7TcQk" rel="noopener noreferrer" target="_blank"><h2>Csatlakozz Discord szerverünkhöz</h2></a>
        <h3>és</h3>
        <a href="https://www.facebook.com/gamecraftsuli" rel="noopener noreferrer" target="_blank"><h2>Kövess minket facebookon</h2></a>
    </div>
  )
}
