import React from 'react';
import Poster from "../../images/ProceduralWorldbuilding_poster.webp";
import BGVideo from "../../videos/TheLibrary.mp4"
import HoudiniBG from "../../images/Houdini_BG.webp"
import CourseDetailsComponent from "./CourseDetailsComponent"


function ProceduralModeling() {

    return (
        <CourseDetailsComponent
        poster={Poster}
        title={<h1 className="bold-title"><span className="blue-text">Houdini</span> alapok</h1>}
        description = {
            <div>
                A procedurális modellezés során a modellek nem "tradicionális" módon vertexről-vertexre készülnek, hanem szabályrendszereket hozunk létre, amelyek alapján a Houdini elkészíti a modelleket és azok akár azonnal megjelennek a játékban. 
                Az ilyen modellek előnye, hogy lehetővé teszik a környezet és a különböző környezeti tárgyak gyors, hatékony és dinamikus létrehozását, akár végtelen számú variációban. Ez a módszer lehetővé teszi a modellek módosítását és finomítását közvetlenül az editorban, amelyek rendkívül fontosak a gyors és hatékony pályaépítésben.
                <br /><br />
                A tanfolyamon az alapoktól kezdünk és megismerjük a Houdini kezelőfelületét és a modellezéshez használt fontosabb eszközöket.
                <br /><br />
                Ezt a tanfolyamot akkor ajánlom, ha:
                <ul>
                    <li>Van már tapasztalatod a tradicionális modellezésben</li>
                    <li>Szeretnéd megismerni a Houdini alapjait</li>
                    <li>Szeretnél gyorsan és hatékonyan dolgozni</li>
                    <li>Érdekelnek a procedurális modellezésben rejlő lehetőségek</li>
                </ul>
                <h3>Nézz bele az előadásba! <a href="https://youtube.com/playlist?list=PLxvo7qZvmu2lYaBeCrt-vfY8KbjrVDtkS" rel="noopener noreferrer" target="_blank">Houdini a játékfejlesztésben (bemutató)</a></h3>
            </div>
        }
        
        video_bg={BGVideo}

        tematics={
            [
                "Houdini és Houdini Engine telepítése", 
                "Bevezetés a Houdini alapokba", 
                "HDA (Houdini Digital Assets) alapok",
                <div><b>Houdini Engine alapok:</b> Pipe Tool</div>,
                <div><b>Houdini Engine alapok:</b> UV és textúrázás</div>,
                <div><b>Houdini Engine alapok:</b> Collider generálás</div>,
                <div><b>The Library project:</b> Előkészületek</div>,
                <div><b>The Library project:</b> Prop Scatter Tool</div>,
                <div><b>The Library project:</b> Ladder Tool</div>,
                <div><b>The Library project:</b> Bookshelf Tool</div>,
                <div><b>The Library project:</b> Building Tool</div>
            ]
        }

        tematicsBG={HoudiniBG}
    />
    );
}

export default ProceduralModeling;