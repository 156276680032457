import React, {createContext, useEffect, useState} from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

export const CourseContext = createContext(true);


export const CourseProvider = (props) => {
    const location = useLocation();
    let course_name = location.pathname.replace('/course/','')

    const [dates_visible, set_dates_visible] = useState(false)
    const [course_data, set_course_data] = useState({})

    const API_URL = process.env.REACT_APP_API_URL

    const fetch_course = () => {
        axios({
            method: "get",
            url: `${API_URL}/api/courses/course/${course_name}/`
        }).then(res => set_course_data(res.data))
    }

    useEffect(() => {
        if(course_name){
            fetch_course()
        }
    }, [course_name])

    return (
        <CourseContext.Provider
            value={{
                course_data: course_data,
                dates_visible: dates_visible,
                set_dates_visible: set_dates_visible
            }}>

            {props.children}

        </CourseContext.Provider>
    )
};