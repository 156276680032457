import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import YouTube from 'react-youtube';

import WhatYouCanDo from "../images/what_you_can_do.webp"
import LearnHome from "../images/lear_at_home.webp"
import UnrealLogo from "../icons/unreal_logo.svg"
import BlenderLogo from "../icons/blender_logo.svg"
import SubstanceLogo from "../icons/substance-painter.svg"
import HoudiniLogo from "../icons/houdinibadge.png"
import CLogo from "../icons/c_logo.svg"
import HeroVideo from "../videos/lobby.mp4"

import BlackTextBox from "./widgets/BlackTextBox";

import PosterKezdo from "../images/drone_fighter_poster.webp"
import PosterHalado from "../images/cloudforestposter3.webp"
import PosterHoudini from "../images/ProceduralWorldbuilding_poster.webp"


function LogoBox({logo, text, delay}){
    const controls = useAnimation();
    const { ref, inView } = useInView();

    const boxVariants = {
      hidden: { scale: 0, opacity: 0 },
      visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: 0.5,
            delay: delay
        }
      }
    }

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return(
        <motion.div className="app-box" ref={ref} initial="hidden" animate={controls} variants={boxVariants}>
            <div className="logo-box"><img src={logo} alt=""/></div>

            <p>{text}</p>
        </motion.div>
    )
}

function CourseCard({poster, title, description, link}){
    const controls = useAnimation();
    const { ref, inView } = useInView({threshold: 0.2});

    const boxVariants = {
        hidden: { y: 100, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
              duration: 1,
              type: "spring"
          }
        }
    }

    useEffect(() => {
    if (inView) {
        controls.start('visible');
    }
    }, [controls, inView]);

    return<Link to={link}>
        <motion.div className='course-card' ref={ref} initial="hidden" animate={controls} variants={boxVariants} whileHover={{scale: 1.02}}>
            <img src={poster} alt="" />
            <div className='card-description'>
                <h2><strong>{title}</strong></h2>
                <hr />
                <h3>{description}</h3>
            </div>
        </motion.div>
    </Link>
}

function Home(props) {
    const opts = {
        height: window.innerWidth < 500? "250":"800",
        width: '100%',
    };

    const player_style = {
        padding: window.innerWidth < 500? "10px 0":"50px",
        backgroundColor: "black"
    }

    return (
        <motion.div className="home-container" initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}}>
            <div className="bg-logo-unreal"><img src={UnrealLogo} alt=""/></div>

            <div className="video-text-container" style={{height: "800px"}}>
                <video className='video-player' autoPlay loop muted playsInline>
                    <source src={HeroVideo} type='video/mp4' />
                </video>
                <BlackTextBox
                    title={<h1 className="bold-title"><span className="blue-text">A játékfejlesztés</span> iskolája</h1>}
                    text={<h2>Játékfejlesztő tanfolyam az alapoktól az első Steam játékodig</h2>}
                />
            </div>

            <div className="center-text-box">
                <h1 className="bold-title">Bemutatkozó</h1>
            </div>
            <YouTube videoId="wZu8-Homtz8" opts={opts} style={player_style}/>

            <div className="center-text-box">
                <h1 className="bold-title">Itt nem csak játszunk.</h1>
                <h1 className="bold-title" style={{color: "#00B6F0"}}>Világokat alkotunk!</h1>
            </div>

            <div className="image-text-container" style={{backgroundImage: `url(${WhatYouCanDo})`}}>

                <BlackTextBox
                    title={<h1 className="bold-title"><span className="blue-text">Mire leszel</span> képes?</h1>}
                    text={<h3>A tanfolyamunkon teljesen kezdő szinttől indulva ismerheted meg velünk a játékok fejlesztésének modern eszközeit. Nagy hangsúlyt fektetünk arra, hogy a ma használt legfejlettebb technológiákat oktassuk neked.

                            <br/><br/>A kurzus végére ismerni fogod a fejlesztéshez szükséges szoftvereket és technológiákat, melyekre saját ötleted megvalósításában építhetsz majd. Velünk együtt haladva fogod elkészíteni első játékodat, amit akár a barátaiddal is megoszthatsz.

                            <br/><br/>Sokat játszol és úgy érzed, hogy kreatív vagy? Akkor minden előképzettséged megvan ahhoz, hogy elkezdd velünk ezt a tanfolyamot.
                        </h3>}
                />
            </div>

            <div className="center-text-box">
                <h1 className="bold-title">Mit <span className="blue-text">fogsz tanulni?</span></h1>
                <h3 style={{textAlign: "center"}}>A legmodernebb fejlesztői eszközök karnyújtásnyira vannak tőled. <br/>Előtted van minden, amire szükséged lesz a következő nagy siker létrehozásához.</h3>

                <div className="app-list-container">
                    <LogoBox logo={UnrealLogo} text={"Unreal Engine"} delay={0.2}/>

                    <LogoBox logo={BlenderLogo} text={"Blender"} delay={0.4}/>

                    <LogoBox logo={HoudiniLogo} text={"Houdini"} delay={1}/>

                    <LogoBox logo={SubstanceLogo} text={"Substance Painter"} delay={0.6}/>

                    {/* <LogoBox logo={AffinityLogo} text={"Affinity Designer"} delay={0.8}/> */}

                    <LogoBox logo={CLogo} text={"C++"} delay={1.2}/>
                </div>
            </div>

            <div className="image-text-container" style={{backgroundImage: `url(${LearnHome})`}}>
                <BlackTextBox
                    title={<h1 className="bold-title">Hol <span className="blue-text">fogsz tanulni?</span> </h1>}
                    text={<h3>Oktatásainkat online tartjuk, amit egy erre a célra kialakított webalkalmazáson keresztül tudsz elérni.

                        <br/><br/>Minden oktatás előtt kapsz egy meghívót az online tartott meetingre. Az oktatásokat rögzítjük és a felvételeket megkapod, hogy semmiről ne maradj le még akkor sem, ha nem tudtál részt venni a meetingen.

                        <br/><br/>Később, szabadidődben akár önállóan is tanulhatsz.
                        </h3>}
                />

                <small className="photo-credit">Photo by <a href="https://unsplash.com/@vheath?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noopener noreferrer" target="_blank">Victoria Heath</a> on <a href="https://unsplash.com/s/photos/learn-in-bed?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noopener noreferrer" target="_blank">Unsplash</a></small>
            </div>

            <div className="center-text-box">
                <h1 className="bold-title"> <span className='blue-text'>Válassz</span> oktatásaink közül</h1>
                <div className='course-cards-layout'>
                    <CourseCard poster={PosterKezdo} link="/course/gdev-kezdo" title={<div><span className='blue-text'> GameCraft:</span> Kezdő</div>} description={
                        <div>Ha még nincs tapasztalatod a játékfejlesztésben vagy érdekelnek az Unreal Engine-ben rejlő lehetőséged és szeretnéd kipróbálni, akkor ez a tanfolyam neked szól.
                        <br /> <br />
                        A tanfolyam egy rövid, 48 órás képzésből áll amit 4 hétvégés bontásban viszünk végig. A tanfolyamon egy egyszerű, kezdő szintű játékot készítünk ahol a cél az, hogy megismerd az Engine kezelőfelületét és erős alapot képezzünk ahhoz, hogy tovább lépj a következő szintre.
                        <br /> <br />
                        Ha teljesen kezdő vagy, akkor nézd meg ezt az oktatásunkat. 
                        </div>
                    }/>
                    <CourseCard poster={PosterHalado} link="/course/gdev-halado" title={<div><span className='blue-text'> GameCraft:</span> Haladó</div>} description={
                        <div>A haladó tanfolyamon tovább bővítjük az Engine-ről szerzett tudásodat és a játék is komolyabb kihívásokat jelent amit itt fogunk elkészíteni. 
                        <br /> <br />
                        A kurzus fő célja a bonyolultabb játékmechanikai eszközök fejlesztése mint az Inventory rendszer, Quest rendszer és interakció a környezettel és más karakterekkel a játékban.
                        <br /> <br />
                        Ha van már tapasztalatod az Engine-el de eddig csak kisebb játékokat fejlesztettél vele, akkor ez a tanfolyam hasznos lesz neked. 
                        </div>
                    }
                    />
                    <CourseCard poster={PosterHoudini} link="/course/houdini-engine" title={<div><span className='blue-text'> Houdini</span> alapok</div>} description={
                        <div>
                            A Houdini egy világszerte elismert szoftver és a filmiparban elengedhetetlen eszköz ha látványos effektekről van szó de nemcsak ezen a területen erős. A játékfejlesztésben is nagy szerepet kapott mert a Houdini Engine pluginnek köszönhetően beépül az Unreal és Unity enginek-be és képes a játék modelljeit közvetlenül az editorban létrehozni, azok paramétereit pedig realtime állíthatod igényeid szerint.
                            <br /><br />
                            Felejtsd el az exportálgatást és ideje hatékonyan dolgozni. Ezen a tanfolyamon megismerheted a procedurális modellezésben rejllő lehetőségeket.
                        </div>
                    }
                    />
                </div>
            </div>
        </motion.div>
    );
}

export default Home;