import React from 'react';
import {motion} from "framer-motion";
import UnrealLogo from "../../icons/unreal_logo.svg";

function RegistrationSentPage(props) {
    return (
        <div className="registration-container">
            <div className="bg-logo-unreal"><img src={UnrealLogo} alt=""/></div>
            <motion.div className="registration-form" initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}}>
                <div className="thank-you">
                    <h1>Köszönöm, hogy a GameCraft.hu-t választottad!</h1>
                    <p>Hamaorsan kapsz egy emailt hasznos információkal a kurzusoddal kapcsolatban.</p>
                </div>

                <hr/>
                <h3>Amíg a levelet várod, néhány tudnivaló:</h3>
                <br/>
                <ul>
                    <li>Olykor előfordul, hogy nem érkezik meg a jelentkezést visszaigazoló email :(
                        <br/>Ha nem kapod meg a levelet pár percen belül, kérlek írj a mail.gamecraft@gmail.com címre!</li>
                    <li>Az oktatás ideje alatt és utána is <a href="https://discord.com/" rel="noopener noreferrer" target="_blank">Discord-on</a> tartjuk a kapcsolatot. Ha még nincs Discord regisztrációd, akkor
                        <a href="https://discord.com/" rel="noopener noreferrer" target="_blank"> itt</a> regisztrálhatsz és letöltheted az app-ot.</li>
                    <li>Ha megvan a Discord, csatlakozz a <a href="https://discord.gg/nRTjD7TcQk" rel="noopener noreferrer" target="_blank"> GameCraft discord szerveréhez</a>!</li>
                    <li>A tanfolyamon a fejlesztéshez az <a href="https://www.unrealengine.com/en-US/" rel="noopener noreferrer" target="_blank">Unreal Engine</a>-t használjuk, amit regisztráció után ingyenesen letölthetsz <a href="https://www.unrealengine.com/en-US/download" rel="noopener noreferrer" target="_blank">innen.</a> </li>
                    <li>A tanfolyamon mi készítjük el a játékok 3D modelljeit, amihez <a href="https://www.blender.org/" rel="noopener noreferrer" target="_blank">Blender</a>-t használunk. Erről is tanulsz majd az oktatáson.
                        Ha még nem töltötted le, akkor <a href="https://www.blender.org/download/" rel="noopener noreferrer" target="_blank">itt</a> ingyenesen megteheted.
                    </li>
                </ul>
            </motion.div>
        </div>
    );
}

export default RegistrationSentPage;