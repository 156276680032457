import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import GamedevKezdo from "./components/CourseDetails/GamedevKezdo";
import GamedevHalado from "./components/CourseDetails/GamedevHalado";
import ProceduralModeling from "./components/CourseDetails/ProceduralModeling";
import ScrollToTop from "./components/ScrollToTop";
import RegistrationPage from "./components/CourseDetails/RegistrationPage";
import RegistrationSentPage from "./components/CourseDetails/RegistrationSentPage";
import {CourseProvider} from "./components/Contexts/CourseContext";
import Gyik from "./components/Gyik";
import About from "./components/About";
import Impresszum from "./components/Impresszum";
import React from "react";



function App() {
  return (
      <BrowserRouter>
          <CourseProvider>
            <ScrollToTop/>
            
            <div className="App">
                <div>
                    <Navbar/>

                    <div className="content-container">
                        <Routes>
                            <Route path="/course/gdev-kezdo" element={<GamedevKezdo/>}/>
                            <Route path="/course/gdev-halado" element={<GamedevHalado/>}/>
                            <Route path="/course/houdini-engine" element={<ProceduralModeling/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/gyik" element={<Gyik/>}/>
                            <Route path="/impresszum" element={<Impresszum/>}/>
                            <Route path="/registration/:group_name" element={<RegistrationPage/>}/>
                            <Route path="/registration-sent" element={<RegistrationSentPage/>}/>
                            <Route path="/" element={<Home/>}/>
                        </Routes>
                    </div>
                </div>

                <Footer/>
            </div>
          </CourseProvider>
      </BrowserRouter>
  );
}

export default App;
