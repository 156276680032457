import React from 'react';
import Poster from "../../images/cloudforestposter3.webp";
import BGVideo from "../../videos/CF_WebVideo.mp4"
import CourseDetailsComponent from "./CourseDetailsComponent"
import YouTube from 'react-youtube';

function GamedevHalado() {
    const opts = {
        height: window.innerWidth < 500? "250":"460",
        width: '100%',
    };

    const player_style = {
        margin: window.innerWidth < 500? "10px 0":"20px",
    }

    return (
        <CourseDetailsComponent
        poster={Poster}
        title={<h1 className="bold-title"><span className="blue-text">Game Craft:</span> Haladó</h1>}
        subtitle = {<h1>Cloud Forest <small>(Action Adventure)</small></h1>}
        description = {
            <div>
                <h3>
                    A kezdő tanfolyam folytatásaként egy kalandjátékot fejlesztünk ami követni próbálja az olyan nagy klasszikusokat mint a Super Mario™ 3D World vagy a Ratchet & Clank. <br/> <br/>
                    A zsáner jellegzetessége, hogy külső nézetben irányítjuk a főhőst (Third Person) és célunk, hogy összegyűjtsük a pályán szétszort különböző speciális tárgyakat miközben egyszerre kell megkűzdenünk a pálya akadályaival és az ellenfelekkel. Játék közben folyamatosan fejleszthetjük a képességeinket és új fegyverekre is szert tehetünk amire szükségünk is lesz, hogy legyőzzük a játék végén várakozó fő ellenfelünket.
                </h3>
                <br />
                <h3>
                    <div>Gameplay:</div>
                    <YouTube videoId="iAomCEjRA1k" opts={opts} style={player_style}/>
                    <br/>Töltsd le és próbáld ki: <a href="https://gamecraftschool.itch.io/cloud-forest" rel="noopener noreferrer" target="_blank">itch.io</a>
                </h3>
            </div>
        }
        
        video_bg={BGVideo}

        tematics={
            [
                "Verziókövetés", 
                "Landscape és Foliage rendszer bemutatása", 
                "Nagy terek világítása és optimalizálás", 
                "Animation Blueprint rendszer", 
                "AI Behavior Tree ismertetése", 
                "Interakciók: NPC dialog rendszer", 
                "Inventory rendszer", 
                "Harc: Melee, Ranged fegyverek kezelése", 
                "Küldetések kezelése: Quests rendszer", 
                "Játék mentés/töltés: A Player/Level State", 
                "Átvezető jelenetek készítése: A Sequencer", 
            ]
        }
    />
    );
}

export default GamedevHalado;