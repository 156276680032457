import React, {useState, useEffect, Fragment} from 'react';
import {motion} from "framer-motion";
import UnrealLogo from "../../icons/unreal_logo.svg";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {validateEmail} from "../../utilities";
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";


function Checkbox({checked}){
    return(
        <div className="checkbox">
            {
                checked&& <motion.i className="fas fa-check" initial={{scale: 0.5}} animate={{scale:0.8}} transition={{duration: 0.1}}/>
            }
        </div>
    )
}

function RadioButton({checked}){
    return(
        <div className="radio-button">
            {
                checked&& <div className="circle"/>
            }
        </div>
    )
}


function RegistrationPage(props) {
    const {group_name} = useParams()

    const [name, set_name] = useState("")
    const [email, set_email] = useState("")
    const [address, set_address] = useState("")
    const [company, set_company] = useState("")
    const [company_address, set_company_address] = useState("")
    const [company_id, set_company_id] = useState("")
    const [message, set_message] = useState("")
    const [payment_method, set_payment_method] = useState(null)
    const [accept_terms, set_accept_terms] = useState(false)
    const [accept_aszf, set_accept_aszf] = useState(false)
    const [form_valid, set_form_valid] = useState(false)
    const [error, set_error] = useState(null)
    const [sending, set_sending] = useState(false)

    const css_override = {
        alignSelf: "center",
        jusifySelf: "center",
    }

    const [is_company, set_is_company] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const offline_course = searchParams.get('offline')

    const check_form = () => {
        set_form_valid(false)

        if(name.length === 0) return
        if(email.length === 0 || !validateEmail(email)) return

        if(address.length === 0) return;
        
        if(offline_course==="false"){
            if(!payment_method) return;
        }else{
            set_payment_method("Egyben")
        }

        if(!accept_terms) return;

        if(!accept_aszf) return;

        set_form_valid(true)
    }

    const registration_clicked = async (e) => {
        e.preventDefault()
        set_error(null)
        set_sending(true)

        try{
            await axios({
                method: "post",
                url: `${API_URL}/api/students/registration/`,
                data:{
                    group: group_name,
                    name: name,
                    email: email,
                    address: address,
                    company: company,
                    company_address: company_address,
                    company_id: company_id,
                    payment_method: payment_method,
                    message: message
                }
            })

            navigate("/registration-sent")
        }catch (err){
            set_error(err.response.data.message)
        }

        set_sending(false)
    }

    useEffect(() => {
        check_form()
    }, [name, email, address, payment_method, accept_terms, accept_aszf])

    return(
        <div className="registration-container" >
            <div className="bg-logo-unreal"><img src={UnrealLogo} alt=""/></div>

            <motion.form className="registration-form" initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}}>
                <h3><i className="fas fa-graduation-cap"/> Jelentkezési lap a <span className="blue-text">{group_name}</span> csoportba</h3>

                <hr/>
                <small>Kapcsolattartás</small>

                <input type="text" placeholder="Név" value={name} onChange={e => set_name(e.target.value)}/>
                <input type="email" placeholder="Email" value={email} onChange={e => set_email(e.target.value)}/>                
                <input type="text" placeholder="Levelezési cím" value={address} onChange={e => set_address(e.target.value)}/>

                <hr/>

                <div className="company-box-check" onClick={e => set_is_company(!is_company)}><Checkbox checked={is_company}/> Cég részére szeretnél számlát?</div>

                { is_company&&
                    <div className="company-box">
                        <input type="text" placeholder="Cégnév" value={company} onChange={e => set_company(e.target.value)}/>
                        <input type="text" placeholder="Telephely" value={company_address} onChange={e => set_company_address(e.target.value)}/>
                        <input type="text" placeholder="Adószám" value={company_id} onChange={e => set_company_id(e.target.value)}/>
                    </div>
                }

                <hr/>
                
                {
                   offline_course==="false"&&
                   <Fragment>
                    <small>Válassz ki egy fizetési opciót</small>

                    <div className="payment-options-container">
                        <div className="paymen-option" onClick={e => set_payment_method("Egyben")}><RadioButton checked={payment_method === "Egyben"}/> Egyben fizetek</div>
                        <div className="paymen-option" onClick={e => set_payment_method("Havonta")}><RadioButton checked={payment_method === "Havonta"}/> Havonta fizetek</div>
                    </div>

                    <hr/>
                   </Fragment>
                }

                <textarea name="" id="" cols="30" rows="5" placeholder="Ha van üzeneted a jelentkezés mellé, akkor itt leírhatod." value={message} onChange={e => set_message(e.target.value)}/>

                <hr/>

                <div className="data-protection-agreement" onClick={e => set_accept_aszf(!accept_aszf)}>
                    <Checkbox checked={accept_aszf}/>
                    <div>
                        Megismertem és elfogadom a gamecraft.hu <a href={`https://www.gamecraft.hu/static/aszf-Gamecraft.pdf`} rel="noopener noreferrer" target="_blank">általános szerződési feltételeit</a>
                    </div>
                </div>

                <br/>

                <div className="data-protection-agreement" onClick={e => set_accept_terms(!accept_terms)}>
                    <Checkbox checked={accept_terms}/>
                    <div>
                        Megismertem és elfogadom a gamecraft.hu <a href={`https://www.gamecraft.hu/static/adatkezelesi-Gamecraft.pdf`} rel="noopener noreferrer" target="_blank">adatkezelési tájékoztatóját</a>
                    </div>
                </div>

                <hr/>
                <small className="error">{error}</small>

                {
                    sending? <PacmanLoader color={"#00B6F0"} loading={true} size={16} css={css_override}/>: form_valid&& <button onClick={registration_clicked}>Jelentkezés elküldése</button>
                }


            </motion.form>

        </div>
    )
}

export default RegistrationPage;