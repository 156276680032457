import React, {Fragment, useEffect} from 'react';
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";


function BlackTextBox({title, text}) {
    const controls = useAnimation();
    const { ref, inView } = useInView({threshold: 0.2});

    const boxVariants = {
      hidden: { x: 100, opacity: 0 },
      visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            type: "spring"
        }
      }
    }

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return(
        <motion.div className="text-box-black" ref={ref} initial="hidden" animate={controls} variants={boxVariants}>
            <div className="left-border">
                {title&&  <Fragment>{title} <br/></Fragment>}
                {text}
            </div>
        </motion.div>
    )
}

export default BlackTextBox;