import React from 'react';
import {motion} from "framer-motion";
import UnrealLogo from "../icons/unreal_logo.svg";


function QuestionBox({question, answer, delay}){
    return(
        <motion.div className="question-container" initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: delay}}>
            <div className="question blue-text">{question}</div>
            <hr/>
            <div className="answer">{answer}</div>
        </motion.div>
    )
}



function Gyik(props) {
    return (
        <div>
            <div className="bg-logo-unreal"><img src={UnrealLogo} alt=""/></div>

            <QuestionBox question="Mit kell telepítenem, hogy követni tudjam a tanfolyamot?" answer={
                <div>
                    Ingyenesen telepíthető szoftverek:
                    <ul>
                        <li><a href="https://discord.com/" rel="noopener noreferrer" target="_blank">Discord</a></li>
                        <li><a href="https://www.unrealengine.com/en-US/" rel="noopener noreferrer" target="_blank">Unreal Engine</a></li>
                        <li><a href="https://www.blender.org/" rel="noopener noreferrer" target="_blank">Blender</a></li>
                    </ul>

                    <br/>
                    Az oktatáson használok fizetős szoftvereket is, amiket nem kötelező megvenned. Az ezekben készült anyagokat meg fogom veled osztani a tanfolyamon.

                    <br/><br/>
                    Fizetős/Limitált ideig kipróbálható szoftverek:
                    <ul>
                        <li><a href="https://affinity.serif.com/en-gb/photo/" rel="noopener noreferrer" target="_blank">Affinity Photo</a></li>
                        <li><a href="https://affinity.serif.com/en-gb/designer/" rel="noopener noreferrer" target="_blank">Affinity Designer</a></li>
                        <li><a href="https://store.steampowered.com/app/1775390/Substance_3D_Painter_2022/" rel="noopener noreferrer" target="_blank">Substance Painter (Steam Edition)</a></li>
                        <li><a href="https://www.ableton.com/en/live/" rel="noopener noreferrer" target="_blank">Ableton Live</a></li>
                    </ul>

                </div>
            } delay={0}/>

            <QuestionBox question="Kell előképzettség a tanfolyamhoz?" answer="A tanfolyamot bárki elkezdheti, aki gyakorlottan kezeli a számítógépet és tud programokat telepíteni. Ez egy gamernek nem lehet probléma. :)" delay={0.1}/>
            <QuestionBox question="Hol zajlik az oktatás?" answer="A gamecraft.hu oktatásai egy erre a célra kialakított online webinar felületen zajlanak. Az oktatásokon úgy vehetsz részt, hogy az oktatás napján a kurzus csoportjában megosztásra kerül a belépéshez használható link. Erre a linkre kattintva indíthatod el a bejelentkezést az online meetingre." delay={0.2}/>
            <QuestionBox question="Készül felvétel az oktatásról?" answer="Minden oktatás felvételre kerül, amit az oktatás után megosztok a csoporttal. Ezek a videók az oktatást követően 7 napig elérhetők letöltésre." delay={0.3}/>
            <QuestionBox question="Kapok oklevelet a kurzus elvégzése után?" answer="A gamecraft.hu oktatásai magánoktatásnak számítanak. Mint ilyen, az iskola nem akkreditált ezért nem adhat papírt az oktatás elvégzése után. A kurzus végén vizsgát sem kell tenned." delay={0.4}/>
            <QuestionBox question="Hogy lehet fizetni az oktatásért?" answer="A jelentkezés után a kurzusokat fizetheted egyben, illetve ha nem vagy biztos abban, hogy elvégzed a teljes tanfolyamot, részletekben is fizethetsz. Ezt eldöntheted a jelentkezési lapon.
                                    A jelentkezésnél kitöltött adatokkal kapsz egy díjbekérőt minden kurzus előtt legkésőbb 1 héttel, amit a kurzus indulásáig kell kifizetned. Az átutalással kapcsolatos adatok ezen a számlán lesznek feltüntetve." delay={0.5}/>
        </div>
    );
}

export default Gyik;