import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import GameCraftLogo from "../images/gamecraft_logo.png"

function Menu({mobile}){
    return (
        <Fragment>
            <div className="nav-menu-item">
                <div>Tanfolyamok</div>
                <div className="submenu-container">
                    {mobile?
                        <Fragment>
                            <a href="/course/gdev-kezdo">GDev: Kezdő</a>
                            <a href="/course/gdev-halado">GDev: Haladó</a>
                            <a href="/course/houdini-engine">Houdini a játékfejlesztésben</a>
                        </Fragment>
                        :
                        <Fragment>
                            <Link to={`/course/gdev-kezdo`}>GDev: Kezdő</Link>
                            <Link to={`/course/gdev-halado`}>GDev: Haladó</Link>
                            <Link to={`/course/houdini-engine`}>Houdini a játékfejlesztésben</Link>
                        </Fragment>
                    }

                </div>
            </div>
            {mobile?
                <Fragment>
                    <a href="/about">Rólunk</a>
                    <br />
                    <a href="/gyik">Gyakori kérdések</a>
                </Fragment>
                :
                <Fragment>
                    <Link to="/about" className="nav-menu-item">Rólunk</Link>
                    <Link to="/gyik" className="nav-menu-item">Gyakori kérdések</Link>
                </Fragment>
            }
        </Fragment>
    )
}

function Navbar(props) {
    const [show_mobile_menu, set_show_mobile_menu] = useState()

    return (
        <div className="navbar">
            <Link to="/" onClick={e=>set_show_mobile_menu(false)}><img src={GameCraftLogo} className="logo" alt=""/></Link>

            <i className="fas fa-bars mobile-menu-button" onClick={e => set_show_mobile_menu(!show_mobile_menu)}/>

            {
                show_mobile_menu&&
                    <div className="mobile-menu-bg" onClick={e=>set_show_mobile_menu(false)}>
                        <div className="mobile-menu-container">
                            <Menu mobile={true}/>
                        </div>
                    </div>
            }

            <div className="nav-menu-container">
                <Menu/>
            </div>

            <div className="spacer"/>

            <div className="footer-item social-icons">
                <a href="https://www.facebook.com/gamecraftsuli" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook"/></a>
                <a href="https://discord.gg/nRTjD7TcQk" rel="noopener noreferrer" target="_blank"><i className="fab fa-discord"/></a>
                <a href="https://www.youtube.com/channel/UC_j-OUcIJ8mdSQPxnB8N2Ag" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube"/></a>
            </div>
        </div>
    );
}

export default Navbar;