import React from 'react';
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <div className="footer-container">
            <div className="content">
                <div className="footer-item">
                    <h2>gamecraft.hu</h2>
                    <p>A játékfejlesztés iskolája</p>
                </div>

                <div className="footer-item">
                    <h2>Kapcsolat</h2>
                    <p>mail.gamecraft@gmail.com</p>

                    <br/>
                    <Link to="/impresszum">Impresszum</Link>
                </div>

                <div className="footer-item social-icons">
                    <a href="https://www.facebook.com/gamecraftsuli" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook"/></a>
                    <a href="https://discord.gg/nRTjD7TcQk" rel="noopener noreferrer" target="_blank"><i className="fab fa-discord"/></a>
                    <a href="https://www.youtube.com/channel/UC_j-OUcIJ8mdSQPxnB8N2Ag" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube"/></a>
                </div>
            </div>
        </div>
    );
}

export default Footer;